/*
 * @description: cpr
 */
import "./common.sass";
import { useEffect, Suspense, lazy } from "react";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import 'antd-mobile-v2/dist/antd-mobile.css';
import store from "./dva";
import { Provider } from "react-redux";
import "antd/dist/antd.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactGa from "react-ga4"
const HomeComponent = lazy(() => import("./pages/home"));
const NFTProjects = lazy(() => import("./pages/nftprojects"));

function divLoading() {
  return (
    <div className="app-wrapper">
      {/* <div className="loading-box">
        <div className="loading"></div>
        <p>loading</p>
      </div> */}
    </div>
  );
}

function Laoding(Component) {
  return (props) => (
    <Suspense fallback={divLoading()}>
      <Component {...props} />
    </Suspense>
  );
}

function App() {
  useEffect(() => {
    if (window?.ethereum) {
      window.ethereum.on('chainChanged', () => {
        window.location.reload();
      });
    }
    ReactGa.initialize("G-J815E9Z590")
  });
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <>
            <Switch>
              {/* <Route
                path={"/home"}
                component={Laoding(HomeComponent)}
              ></Route> */}
              <Route
                path={"/home"}
                component={Laoding(NFTProjects)}
              ></Route>
              <Redirect to={"home"}></Redirect>
            </Switch>
          </>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
