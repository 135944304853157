const WebStyleModel = {
  namespace: 'webstyle',

  state: {
    clientWidth: document?.body?.clientWidth,
    scrollTop: 0,
    loadingDuration: 3000,
  },

  effects: {
    *setContext({ payload }, { call, put }) {
      yield put({
        type: 'setState',
        payload
      });
    }

  },

  reducers: {
    setState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    }
  }
}

export default WebStyleModel;