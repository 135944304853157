import axios from '../service/request';
import { getShowAddress } from '../util/common';
import localStorage from "localStorage";
import { Toast } from "antd-mobile";

const LoginModel = {
  namespace: 'login',
  state: {
    username: '',
    login: false,
    loginType: 'metamask',
    showLoginModal: false,
    address: '',
    showAddress: '',
    nickname: '',
    email: '',
    gender: 0,
    userCurrentTradeTokenId: 0,
    h: '1',
    guildId: '1',
    avatar: {},
    wallectConnector: null,
    shareLink: '',
    limitedLandCodes: ['MetaTokyo'],
    businessId: '',
  },
  effects: {
    *login({ payload }, { call, put }) {
      let address = "";
      if (JSON.stringify(payload) === "{}" || payload === undefined) {
        if (window.ethereum !== undefined) {
          const accounts = yield window.ethereum.request({
            method: "eth_requestAccounts",
          });
          address = accounts[0];
        }
      } else {
        address = payload.address;
      }
      if (!address) {
        let wcStr = '';
        try {
          wcStr = localStorage.getItem("walletconnect");
        } catch (error) {
        }
        if (wcStr) {
          let wcObj = JSON.parse(wcStr);
          address = wcObj["accounts"][0];
        }
      }
      if (!address) {
        return
      }
      yield put({
        type: 'setState',
        payload: { ...payload }
      })
      let userProfileRes = yield call(axios.get, `/u/${address}/profile`);

      let userProfile = userProfileRes.data || {};
      let showAddress = getShowAddress(address);

      userProfile.showAddress = showAddress;
      yield put({
        type: 'setState',
        payload: { ...payload, address, ...userProfile }
      })
    },
    *logout({ payload }, { call, put }) {
      yield put({
        type: 'setStateClear'
      })
    },
    *setUsernamePassword({ payload }, { call, put }) {
      yield put({
        type: 'setState',
        payload: { ...payload }
      })
    },
    *setUserCurrentTradeTokenId({ payload }, { put }) {
      // console.log('exec setUserCurrentTradeTokenId, payload', payload)
      yield put({
        type: 'setState',
        payload: { ...payload }
      })
    },
    *setUpper({ payload }, { call, put, select }) {
      if (!payload.address || (!payload.h && !payload.guildId)) {
        return
      }

      let setUpperRes = yield call(axios.post, '/u/userSetUpper', payload, {
        headers: {
          'content-type': 'application/json'
        }
      })
      if (setUpperRes.code === 0) {
        if (setUpperRes.data?.h) {
          localStorage.removeItem('parentsId');
          Toast.info('Connected')
        }
        yield put({
          type: 'setState',
          payload: { h: setUpperRes.data?.h, guildId: setUpperRes.data?.guildId }
        })
        yield put({
          type: 'login'
        })
      }

    },

    *showLoginModal({ payload }, { put }) {
      yield put({
        type: 'setState',
        payload: { showLoginModal: payload.showLoginModal }
      })
    },

    *setContext({ payload }, { call, put }) {
      yield put({
        type: 'setState',
        payload
      });
    }
  },
  reducers: {
    setState(state, { payload }) {
      return {
        ...state,
        ...payload
      }
    },
    setStateClear(state,) {
      localStorage.removeItem("walletconnect");
      return {
        ...state,
        username: '',
        login: false,
        address: '',
        showAddress: '',
        nickname: '',
        email: '',
        gender: 0,
        userCurrentTradeTokenId: 0,
        showLoginModal: false,
        shareLink: '',
      }
    }
  },

}

export default LoginModel;