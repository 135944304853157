import { create } from 'dva-core';
import { persistEnhancer } from 'dva-model-persist';
import loginModel from './model/LoginModel';
import webStyleModel from './model/WebStyleModel';


const app = create();
app.model(loginModel);
app.model(webStyleModel);

app.use({
  extraEnhancers: [
    persistEnhancer()
  ],
});
app.start();
export default app._store;

