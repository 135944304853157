import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getLanguageJson } from '@/util/language';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react';

// import VConsole from 'vconsole';
// 加载语言包
getLanguageJson();
// new VConsole();
const isDev = process.env.NODE_ENV === 'development';

if (isDev) {
  ReactDOM.render(
    <>
      {/* <MobileMenu /> */}
      <App />
    </>
    , document.getElementById('root'));
} else {
  Bugsnag.start({
    apiKey: 'ab75a3dfb873af7b9f33590b1235c0d5',
    plugins: [new BugsnagPluginReact()]
  })
  const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)
  ReactDOM.render(
    <ErrorBoundary>
      {/* <MobileMenu /> */}
      <App />
    </ErrorBoundary>
    ,
    document.getElementById('root')
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
