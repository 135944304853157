// eslint-disable-next-line import/no-anonymous-default-export
export default {
  primaryColor: '#4F9DDE',
  green: '#34D859',
  gray: 'rgba(24, 28, 47, 0.2)',
  red: '#F34848',
  red2: 'rgba(236, 90, 81, 0.8)',
  darkPurple: '#292F4C',
  gray2: "rgba(241, 237, 137, 0.3)",
  gray3: "rgba(24, 28, 47, 0.3)",
  gray4: "#353945",
  gray5: "#D8D8D8",
  gray6: "#23262F",
  gray7: "rgba(255, 255, 255, 0.18)",
  secondary1: "#777E90",
  grayDark: "#181C2F",
  disableBlue: "#3772FF",
  grayDark2: "rgba(33, 33, 33, 0.58)",
  grediantGray: "linear-gradient(119.19deg, #FFFFFF 0%, #F8F8F8 65.34%, #FFFFFF 100%)",
  grediantPrimary: "linear-gradient(to right, #6D0BBD , #445FF5 )",
  grediantBlue: "linear-gradient(to right, #3D8AFF , #7635FF )",
  bgWhite: "white",
  inactiveColor: "rgba(41, 47, 76, 0.3)",
  inactiveColorDark: "white",

  normal: "0.8rem",
  medium: "1rem",
  large: "1.5rem",
  xlarge: "2rem",
  xxlarge: "2.4rem",
  small: "1.2rem",
  xsmall: "1rem",
  xxsmall: "0.8rem",
};