import Web3 from "web3";
var BN = Web3.utils.BN;


export const getShowAddress = (address) => {
  if (address && address.length >= 10) {
    return address.substring(0, 6) + '...' + address.substring(address.length - 4);
  }
  return ''
}

export const toThousands = (num) => {
  // num = '1234567890.111';
  if (num === undefined) return '0';
  num = Web3.utils.fromWei(num);
  let numArr = num.toString().split('.');
  let int = numArr[0];
  let decmial = numArr[1] ? '.' + numArr[1] : '';
  let step = 0;
  // debugger
  let resultArr = [];
  for (let n = int.length - 1; n >= 0; n--) {
    resultArr.push(int[n]);
    step++;
    if (step >= 3 && n !== 0) {
      resultArr.push(",");
      step = 0;
    }
  }
  return resultArr.reverse().join("") + decmial;
};

export const toThousandsRound = (num) => {
  // num = '1234567890.111';
  // console.log('toThousandsRound num: ', num)
  if (num === undefined || !num) return '0';
  num = Web3.utils.fromWei(num);
  let numArr = num.toString().split('.');
  let int = numArr[0];
  let decmial = numArr[1] ? '.' + numArr[1] : '';
  let step = 0;
  if (decmial !== '') {
    int = new BN(int).add(new BN('1')).toString()
  }
  // debugger
  let resultArr = [];
  for (let n = int.length - 1; n >= 0; n--) {
    resultArr.push(int[n]);
    step++;
    if (step >= 3 && n !== 0) {
      resultArr.push(",");
      step = 0;
    }
  }
  return resultArr.reverse().join("");
};

export const toBillion = (amounWei) => {
  // console.log('to billin: ', amounWei);
  if (amounWei === undefined) amounWei = '0';
  // if (typeof amounWei == 'string') {
  //   amounWei = new BN(amounWei);
  // }
  let amount = Web3.utils.fromWei(amounWei);
  // console.log(amount);
  return Web3.utils.fromWei(amount, 'Gwei')
}

export const toEther = (amountWei) => {
  return Web3.utils.fromWei(amountWei);
}

export const calPercentage = (num1, num2) => {

  if (num1 === undefined || num2 === undefined || num1 === '0' || num2 === '0') {
    return "1";
  }
  // console.log('num1: ', num1)
  // console.log('num2: ', num2)
  num1 = Web3.utils.fromWei(num1);
  num2 = Web3.utils.fromWei(num2);

  if (num1.indexOf('.') !== -1) {
    num1 = num1.substring(0, num1.indexOf('.'))
  }
  if (num2.indexOf('.') !== -1) {
    num2 = num2.substring(0, num2.indexOf('.'))
  }
  // console.log('num1-1: ', num1)
  // console.log('num2-1: ', num2)
  let percentage = num2 / num1;
  // console.log('percentage fixed: ', percentage.toFixed(2));
  let r = 100 / percentage.toFixed(2);
  if (r < 20) {
    // r += parseInt(3);
  }
  r = r.toFixed(2);
  // console.log('final percent: ', r);
  return r;
}

function sleep(time) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('sleepDone')
    }, time)
  })
}

export { sleep }

/**
 * get current login account
 */
export const getCurrentAccount = async () => {
  let address = "";
  if (window.ethereum) {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    address = accounts[0];
    return address;
  } else {
    let jsonObjStr = localStorage.getItem("walletconnect");
    let jsonObj = JSON.parse(jsonObjStr);
    return jsonObj?.accounts[0];
  }

}
